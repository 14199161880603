.s-post-detail__meta {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: start;
  }
}

.s-post-detail__share-links {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }
}

.s-post-detail__share {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}
