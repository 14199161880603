.g-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  row-gap: 56px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }

  &.--compact {
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }
  }

  &.--inline {
    display: flex;
    flex-direction: column;
  }
}
