@use "settings/s-colors" as colors;

.g-steps {
  row-gap: 80px;
}

.g-steps__cell {
  &:last-child {
    .i-step {
      &::before {
        display: none;
      }
    }
  }

  &:nth-child(4) {
    .i-step::before {
      @include media-breakpoint-up(xl) {
        --left: 0;

        width: calc(100% + 56px);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &:nth-child(-n + 6):nth-child(n + 4) {
      .i-step {
        --count-left: 0;
        --count-right: auto;
        --transform-count: translate(-50%, -50%);
        --transform-count-bg: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
