.i-event {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    max-width: 320px;
    margin-inline: auto;
  }
}

.i-event__image-link {
  @include media-breakpoint-up(md) {
    width: 280px;
    height: 210px;
  }
}
