@use "settings/s-colors" as colors;

.js-open-section {
  &.--background .s-separator {
    --icon-bg: #{colors.$primary};
  }

  .js-open-section__body {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease;
  }

  &.--open {
    .js-open-section__body {
      grid-template-rows: 1fr;
    }
  }
}
