.i-post.--hero {
  @include media-breakpoint-up(lg) {
    display: grid;
    gap: 32px;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-down(lg) {
    max-width: 688px;
    margin-inline: auto;
  }

  .i-post__tags {
    margin-top: 0;
  }

  .i-post__content-col {
    @include media-breakpoint-up(lg) {
      padding-block: 32px;
    }
  }

  .i-post__content {
    justify-content: center;
  }
}
