@use "settings/s-colors" as colors;

.s-separator {
  --color: #{colors.$primary};
  --icon-bg: #{colors.$primary-400};

  &.--color-primary-400 {
    --color: #{colors.$primary-400};
  }
}

.s-separator__wrap {
  height: 58px;
}

.s-separator__line {
  background-color: var(--color);
  height: 1px;
  max-width: 1408px;
  width: 100%;
  pointer-events: none;
}

.s-separator__icon {
  width: 58px;
  min-height: 58px;
  box-shadow: 0 0 0 1px var(--color);
  background-color: var(--icon-bg);
  pointer-events: none;
}
