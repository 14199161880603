@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    color: colors.$black;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$black;
      text-decoration-color: colors.$black;
    }
  }

  &.--primary {
    color: colors.$conversion-500;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$conversion-500;
      text-decoration-color: colors.$conversion-500;
    }
  }
}
