@use "settings/s-colors" as colors;

.f-contact {
  --padding: 24px;

  padding: var(--padding);
  background: colors.$conversion-500;

  @include media-breakpoint-up(md) {
    --padding: 40px;
  }
}

.f-contact__grid {
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-contact__submit {
  @include media-breakpoint-down(xxl) {
    margin-left: auto;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.f-contact__terms {
  a {
    color: colors.$primary;
    font-weight: 600;
  }

  @include media-breakpoint-up(xxl) {
    margin-left: auto;
  }
}
