@use "settings/s-colors" as colors;

.f-error {
  --display: none;

  right: 8px;
  display: var(--display);
  z-index: 2;

  &.--show {
    --display: flex;
  }
}
