.g-buttons {
  --spacing: 72px;

  margin-top: var(--spacing);

  &.--spacing-sm {
    --spacing: 48px;
  }

  &.--spacing-xs {
    --spacing: 24px;
  }

  &.--spacing-none {
    --spacing: 0;
  }
}
