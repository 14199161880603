@use "settings/s-general" as general;
@use "settings/s-colors" as colors;

.search-form__wrap {
  display: flex;
  align-items: center;
  position: relative;
  gap: general.$spacing * 2 general.$spacing * 4;
  max-width: 80ch;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: colors.$white;
  box-shadow: inset 0 0 0 1px colors.$gray-200;
  display: flex;
  flex-grow: 1;
  position: relative;
}

.search-form__input {
  border: 0;
  background: none;
  padding: 0 general.$spacing * 2;
  flex-grow: 1;
  outline: 0;
  color: colors.$black-400;

  @include media-breakpoint-down(sm) {
    min-width: 0;
  }
}

.search-form__submit {
  height: general.$spacing * 6;
  width: general.$spacing * 6;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;

  svg {
    width: 18px;
    height: 18px;
    transition: fill 0.2s;
    fill: colors.$primary;
  }

  &:hover {
    background-color: colors.$primary;

    svg {
      fill: colors.$white;
    }
  }

  &:active,
  &:focus {
    outline: 0;
  }
}
