@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.entry-content {
  blockquote {
    font-weight: 400;
    padding: 16px;
    margin-block: 32px;
    position: relative;
    font-style: italic;
    background-color: colors.$primary;
    display: flex;
    flex-direction: column;
    gap: 8px;

    p {
      font-style: italic;
      margin-bottom: 0;
    }
  }
}
