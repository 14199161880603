@use "settings/s-colors" as colors;

.i-job {
  --padding-x: 24px;
  --padding-y: 32px;

  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
}

.i-job__icon {
  width: 20px;
  height: 20px;
}
