@use "settings/s-colors" as colors;

.g-reviews-slider__button {
  pointer-events: auto;

  @include media-breakpoint-down(sm) {
    &.btn.--icon-only {
      --min-height: 40px;
    }
  }

  @media (width <= 359px) {
    &.btn.--icon-only {
      --min-height: 32px;

      & .icon.--lg {
        --size: 20px;
      }
    }
  }
}

.g-reviews-slider {
  &.swiper {
    display: flex;
    align-items: center;
    overflow: initial;
    height: 100%;
  }

  & .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    box-sizing: border-box;
    height: auto;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .swiper-slide-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.g-reviews-slider__buttons::before {
  content: "";
  position: absolute;
  background-color: colors.$white;
  top: 50%;
  left: 0;
  transform: translate(calc(-100% + 80px), -50%);
  width: 416px;
  height: 1px;
  z-index: 1;
  pointer-events: none;
}
