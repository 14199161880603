@use "settings/s-colors" as colors;

.i-step {
  --size: 96px;
  --padding-x: 32px;
  --left: var(--padding-x);
  --count-size: 29px;
  --count-left: auto;
  --count-right: 0;
  --transform-count: translate(50%, -50%);
  --transform-count-bg: translate(50%, -50%) rotate(45deg);

  padding-inline: var(--padding-x);

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: var(--left);
    top: calc(var(--size) / 2);
    transform: translate(0, -50%);
    height: 1px;
    width: 100%;
    background-color: colors.$white;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .i-step__icon {
    width: var(--size);
    height: var(--size);
  }

  .i-step__count {
    left: var(--count-left);
    right: var(--count-right);
    transform: var(--transform-count);
  }

  .i-step__count-bg {
    width: var(--count-size);
    height: var(--count-size);
    left: var(--count-left);
    right: var(--count-right);
    transform: var(--transform-count-bg);
  }

  @include media-breakpoint-up(xxl) {
    --padding-x: 56px;
  }
}
