@use "settings/s-colors" as colors;

.i-notification {
  --min-height: 48px;

  min-height: var(--min-height);

  @include media-breakpoint-up(xl) {
    --min-height: 44px;

    padding-bottom: 4px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.i-notification__container {
  width: min(100% - 32px, 1408px);

  @include media-breakpoint-down(sm) {
    gap: 8px;
  }
}

.i-notification__body {
  @include media-breakpoint-down(sm) {
    align-items: flex-start;
  }
}
