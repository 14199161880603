.content-w-aside__content {
  .base-section {
    .container {
      padding-inline: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__row {
  gap: 32px;
  row-gap: 64px;

  @include media-breakpoint-up(lg) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
  }

  & > .content-w-aside__content:last-child {
    grid-column: 3 / span 8;
  }
}

.content-w-aside__aside {
  @include media-breakpoint-up(lg) {
    grid-column: span 4/ -1;
  }

  @include media-breakpoint-up(xxl) {
    grid-column: span 3/ -1;
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);

  padding-block: var(--spacing);
}
