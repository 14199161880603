.entry-content {
  > a {
    display: block;
  }

  img {
    max-width: 100%;
    margin-block: 16px;
  }
}
