@use "settings/s-colors" as colors;

.i-hero-slide__body {
  --radius: 8px;

  max-width: 568px;
  border-radius: var(--radius);
  backdrop-filter: blur(20px);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$white;
    z-index: -1;
    opacity: 0.4;
    border-radius: var(--radius);
  }

  @include media-breakpoint-down(lg) {
    position: static;
    transform: translate3d(0, 0, 0);
    left: auto;
    right: auto;
    max-width: 100%;
    gap: 8px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.i-hero-slide {
  --offset: 64px;

  &.--left {
    .i-hero-slide__body {
      left: var(--offset);
    }
  }

  &.--right {
    .i-hero-slide__body {
      right: var(--offset);
    }
  }

  &.--center {
    .i-hero-slide__body {
      @include media-breakpoint-up(lg) {
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 16px;
  }
}

.i-hero-slide__image {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
