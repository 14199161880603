@use "settings/s-colors" as colors;

.g-signpost {
  &.--cols-4 {
    @include media-breakpoint-between(md, xxl) {
      max-width: 718px;
      margin-inline: auto;
    }

    @include media-breakpoint-down(md) {
      max-width: 360px;
      margin-inline: auto;
    }
  }

  &.--cols-3 {
    @include media-breakpoint-down(md) {
      max-width: 448px;
      margin-inline: auto;
    }
  }
}
