@use "settings/s-colors" as colors;

.s-contact-form__row {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 3fr 4fr;
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: 1fr 2fr;
  }
}

.s-contact-form__contact {
  --padding-x: 24px;

  padding-inline: var(--padding-x);
  gap: 56px;

  &::before {
    content: "";
    position: absolute;
    background-color: colors.$white;
    left: 120px;
    bottom: 0;
    transform: translate(-50%, 52px);
    width: 1px;
    height: 88px;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    --padding-x: 40px;
  }

  @include media-breakpoint-up(xl) {
    --padding-x: 72px;
  }
}

.s-contact-form__contact-icon {
  width: 96px;
  height: 96px;
}

.s-contact-form__mail {
  word-break: break-all;
}
