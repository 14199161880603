@use "settings/s-colors" as colors;
@use "settings/s-fonts" as fonts;

.btn__icon {
  .icon {
    --size: var(--svg-size);
  }

  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }
}

.btn {
  --border-radius: 0px;
  --font-size: 18px;
  --font-weight: 500;
  --font-color: #{colors.$primary};
  --font-family: #{fonts.$primary};
  --padding-y: 16px;
  --padding-x: 56px;
  --min-height: 64px;
  --svg-size: 16px;

  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  user-select: none;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;

  &:hover {
    text-decoration: none;
    color: var(--font-color);
  }

  &:focus,
  &:active {
    outline: 0;
    color: var(--font-color);
    text-decoration: none;
  }

  path {
    fill: var(--font-color);
  }

  &.--icon-before {
    .btn__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: var(--min-height);
    height: var(--min-height);
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .btn__title {
      display: none;
    }
  }

  &.--full-width-mobile {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
