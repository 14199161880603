@use "settings/s-colors.scss" as colors;

.i-usp {
  --min-height: 96px;

  @include media-breakpoint-up(sm) {
    text-align: left;
    flex-direction: row;
    align-items: flex-start;

    .i-usp__body {
      align-items: flex-start;
    }
  }
}

.i-usp__icon {
  width: var(--min-height);
  height: var(--min-height);
  background-color: colors.$primary-400;
}

.i-usp__heading {
  display: flex;
  align-items: center;

  @include media-breakpoint-up(sm) {
    min-height: var(--min-height);
  }
}
