@use "settings/s-colors" as colors;

.i-person {
  border: 1px solid colors.$primary;
}

.i-person__title {
  word-break: break-word;
}

.i-person__email {
  word-break: break-all;
}
