@use "settings/s-colors" as colors;

.i-hero__body {
  --offset-left: 0;
  --offset-right: 0;
  --order: 1;

  padding-left: var(--offset-left);
  padding-right: var(--offset-right);
  order: var(--order);

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-color: colors.$white;
  }

  &::before {
    top: 64px;
    right: 0;
    transform: translateX(84px);
    width: 416px;
    height: 1px;
    z-index: 1;
  }

  &::after {
    bottom: 140px;
    left: 0;
    transform: translateX(-40px);
    width: 240px;
    height: 1px;
  }

  @include media-breakpoint-up(lg) {
    --offset-left: 24px;
    --offset-right: 48px;
    --order: 0;
  }

  @include media-breakpoint-down(xxl) {
    &::before,
    &::after {
      display: none;
    }
  }
}

.i-hero {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.i-hero__img {
  img {
    aspect-ratio: 1 / 0.698;
  }
}

.i-hero__img-decor {
  transform: translate(56px, 56px);
}

.i-hero__header {
  @include media-breakpoint-up(lg) {
    max-width: 627px;
  }

  .base-heading.u-h1 {
    text-wrap: pretty;

    @include media-breakpoint-down(xxl) {
      --size: 48px;
    }

    @include media-breakpoint-down(xl) {
      --size: 32px;
    }
  }
}

.i-hero__description {
  @include media-breakpoint-up(lg) {
    max-width: 646px;
  }
}
