.g-posts-grid {
  display: grid;
  gap: 32px;
  row-gap: 72px;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell {
  &:first-of-type {
    grid-column: 1 / -1;
  }
}
