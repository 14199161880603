@use "settings/s-colors.scss" as colors;
@use "settings/s-fonts.scss" as fonts;

.link__icon {
  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }
}

.link {
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  text-decoration-color: transparent;
  font-weight: 500;

  &:hover {
    text-decoration-color: inherit;
  }

  &.--icon-before {
    .link__icon {
      order: -1;
    }
  }

  &.--underline-initial {
    text-decoration-color: inherit;

    &:hover,
    &:focus {
      text-decoration-color: transparent;
    }
  }

  &.--font-family-secondary {
    font-family: fonts.$secondary;
  }
}
