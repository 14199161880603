*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (min-width: 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (min-width: 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-xxxl: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-12 {
  flex: none;
  width: 100%;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

@media (min-width: 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (min-width: 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (min-width: 75em) {
  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }
}

@media (min-width: 93.75em) {
  .col-xxl-3 {
    flex: none;
    width: 25%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-50 {
  opacity: .5;
}

.overflow-hidden {
  overflow: hidden;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-self-end {
  align-self: flex-end;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.ms-1 {
  margin-left: .5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-6 {
  padding: 3rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-6 {
  padding-top: 3rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.ps-2 {
  padding-left: 1rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.row-gap-2 {
  row-gap: 1rem;
}

.row-gap-4 {
  row-gap: 2rem;
}

.row-gap-5 {
  row-gap: 2.5rem;
}

.column-gap-1 {
  column-gap: .5rem;
}

.column-gap-4 {
  column-gap: 2rem;
}

.column-gap-5 {
  column-gap: 2.5rem;
}

.column-gap-6 {
  column-gap: 3rem;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

@media (min-width: 48em) {
  .flex-md-row {
    flex-direction: row;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .py-md-5 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

@media (min-width: 62em) {
  .d-lg-block {
    display: block;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .ms-lg-5 {
    margin-left: 2.5rem;
  }

  .pt-lg-3 {
    padding-top: 1.5rem;
  }
}

@media (min-width: 75em) {
  .d-xl-block {
    display: block;
  }
}

@media (min-width: 93.75em) {
  .d-xxl-block {
    display: block;
  }

  .flex-xxl-row {
    flex-direction: row;
  }

  .align-items-xxl-center {
    align-items: center;
  }
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (max-width: 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 359px) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.fancybox__container {
  --fancybox-accent-color: #211e33;
}

.flatpickr-calendar {
  --theme-color: #171429;
  --theme-color-hover: #211e33;
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: #171429;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  min-height: calc(var(--vh, 1vh) * 100);
  color: #fff;
  background-color: #211e33;
  flex-direction: column;
  min-height: 100vh;
  font-family: Outfit, sans-serif;
  line-height: 1.5;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
  margin-bottom: 0;
  font-family: Libre Baskerville, serif;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  scroll-behavior: smooth;
  height: 100%;
  scroll-padding-top: 8rem;
}

@media (max-width: 93.749em) {
  html {
    scroll-padding-top: 7.75rem;
  }
}

@media (max-width: 74.999em) {
  html {
    scroll-padding-top: 5.25rem;
  }
}

@media (max-width: 35.999em) {
  html {
    scroll-padding-top: 4.75rem;
  }
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: #fff;
  text-decoration: underline;
  transition: text-decoration-color .3s;
  display: inline;
}

a:hover, a:focus {
  color: #fff;
  text-decoration-color: #0000;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
  overflow-x: hidden;
}

p {
  text-wrap: pretty;
}

:root {
  --section-spacing: 6rem;
}

@media (max-width: 35.999em) {
  :root {
    --section-spacing: 3rem;
  }
}

strong, b {
  font-weight: 700;
}

.js-open-section.--background .s-separator {
  --icon-bg: #171429;
}

.js-open-section .js-open-section__body {
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
}

.js-open-section.--open .js-open-section__body {
  grid-template-rows: 1fr;
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

label[for] {
  cursor: pointer;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

.entry-content .alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.entry-content .alignright {
  float: right;
  margin: 0 0 1.25rem 1.875rem;
}

.entry-content .alignright img {
  margin-block: 0;
}

.entry-content .alignleft {
  float: left;
  margin: 0 1.875rem 1.25rem 0;
}

.entry-content .alignleft img {
  margin-block: 0;
}

.entry-content .aligncenter, .entry-content div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.entry-content .aligncenter img, .entry-content div.aligncenter img {
  margin-block: 0;
  margin-inline: auto;
}

@media (max-width: 35.999em) {
  .entry-content .alignleft, .entry-content .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

.u-bg-primary {
  background-color: #171429;
}

.u-bg-primary-100 {
  background-color: #e7f0ff;
}

.u-bg-primary-400 {
  background-color: #211e33;
}

.u-bg-conversion {
  background-color: #dfbe81;
}

.u-bg-gray {
  background-color: #a9a9b2;
}

.u-bg-white {
  background-color: #fff;
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-3 {
  --lines: 3;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #171429;
}

.u-color-white {
  color: #fff;
}

.u-color-conversion {
  color: #dfbe81;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .25rem);
}

.u-fz-sm {
  --size: .875rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-lg {
  --size: 1.125rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .375rem);
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-700 {
  font-weight: 700;
}

.u-h1 {
  --size: 4rem;
  --line-height: calc(var(--size)  + .5rem);
}

@media (max-width: 47.999em) {
  .u-h1 {
    --size: 3rem;
  }
}

.u-h2 {
  --size: 3rem;
  --line-height: calc(var(--size)  + .5rem);
}

@media (max-width: 47.999em) {
  .u-h2 {
    --size: 2rem;
  }
}

.u-h3 {
  --size: 2rem;
  --line-height: calc(var(--size)  + .5rem);
}

@media (max-width: 47.999em) {
  .u-h3 {
    --size: 1.125rem;
  }
}

.u-h4 {
  --size: 1.125rem;
  --line-height: calc(var(--size)  + .375rem);
}

@media (max-width: 47.999em) {
  .u-h4 {
    --size: 1.25rem;
  }
}

.u-h5 {
  --size: 1rem;
  --line-height: calc(var(--size)  + .25rem);
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5 {
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  pointer-events: none;
  position: relative;
}

.--loading:before {
  content: "";
  z-index: 9000;
  opacity: .8;
  background-color: #a9a9b2;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  z-index: 9001;
  border: .25rem solid #dfbe81;
  border-bottom-color: #171429;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: #7387ac;
  --os-handle-bg-hover: #211e33;
  --os-handle-bg-active: #171429;
  --os-handle-border-radius: .25rem;
  --os-size: .5rem;
  --os-track-bg: #ebebeb;
  --os-track-bg-hover: #ebebeb;
  --os-track-bg-active: #ebebeb;
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-left-0 {
  left: 0;
}

.u-right-0 {
  right: 0;
}

.u-top-0 {
  top: 0;
}

.u-bottom-0 {
  bottom: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-rounded-full {
  border-radius: 624.938rem;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.base-header {
  --spacing: 4.5rem;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  max-width: 58rem;
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header.--spacing-none {
  --spacing: 0;
}

.base-header.--spacing-sm {
  --spacing: 1.5rem;
}

.base-header__description p {
  margin-bottom: 0;
}

.base-section {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}

@media (max-width: 35.999em) {
  .base-section {
    --spacing: 3rem;
  }
}

.base-section.--background {
  background-color: #171429;
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

.base-section.--none {
  --spacing: 0;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--md {
  --min-height: 3rem;
}

.btn.--sm {
  --font-size: .875rem;
  --padding-y: .625rem;
  --padding-x: 1.5rem;
  --min-height: 2.5rem;
}

.btn.--xs {
  --font-size: .75rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 2.5rem;
  --svg-size: 1rem;
}

.btn.--xxs {
  --font-size: 1rem;
  --padding-y: .25rem;
  --padding-x: 1rem;
  --min-height: 1.25rem;
  --svg-size: 1rem;
}

.btn.--primary {
  --background: #dfbe81;
  background-color: var(--background);
  z-index: 1;
  overflow: hidden;
}

.btn.--primary:after {
  content: "";
  pointer-events: none;
  z-index: -2;
  background-color: #ffffff4d;
  position: absolute;
  inset: 0;
}

.btn.--primary:before {
  content: "";
  pointer-events: none;
  background-color: var(--background);
  z-index: -1;
  transition: transform .3s;
  position: absolute;
  inset: 0;
  transform: translateY(0);
}

@media (hover: hover) {
  .btn.--primary:hover:before {
    transform: translateY(100%);
  }
}

.btn.--secondary {
  --background: #211e33;
  --background-cover: #171429;
  --font-color: #dfbe81;
  background-color: var(--background);
  z-index: 1;
  overflow: hidden;
}

.btn.--secondary:before {
  content: "";
  pointer-events: none;
  background-color: var(--background-cover);
  z-index: -1;
  transition: transform .3s;
  position: absolute;
  inset: 0;
  transform: translateY(0);
}

@media (hover: hover) {
  .btn.--secondary:hover:before {
    transform: translateY(100%);
  }
}

.btn.--secondary-revert {
  --background: #171429;
  --background-cover: #211e33;
  --font-color: #dfbe81;
  background-color: var(--background);
  z-index: 1;
  overflow: hidden;
}

.btn.--secondary-revert:before {
  content: "";
  pointer-events: none;
  background-color: var(--background-cover);
  z-index: -1;
  transition: transform .3s;
  position: absolute;
  inset: 0;
  transform: translateY(0);
}

@media (hover: hover) {
  .btn.--secondary-revert:hover:before {
    transform: translateY(100%);
  }
}

.btn.--outline {
  --font-color: #dfbe81;
  --border-color: #171429;
  --background: transparent;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  will-change: background-color, border-color;
  transition: background-color .3s, border-color .3s;
}

@media (hover: hover) {
  .btn.--outline:hover {
    --background: #171429;
  }
}

.btn.--ghost {
  --background: transparent;
  --font-color: #171429;
  background-color: var(--background);
  will-change: background-color;
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--ghost:hover {
    --background: #e7f0ff;
  }
}

.btn.--social {
  --background: #fff;
  --font-color: #171429;
  background-color: var(--background);
  will-change: background-color;
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--social:hover {
    --background: #fff;
  }
}

.btn__icon .icon {
  --size: var(--svg-size);
}

.btn__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.btn {
  --border-radius: 0;
  --font-size: 1.125rem;
  --font-weight: 500;
  --font-color: #171429;
  --font-family: Outfit, sans-serif;
  --padding-y: 1rem;
  --padding-x: 3.5rem;
  --min-height: 4rem;
  --svg-size: 1rem;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  text-decoration: none;
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn path {
  fill: var(--font-color);
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  min-height: auto;
  padding: 0;
  display: flex;
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (max-width: 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}

.content-w-aside__content .base-section .container {
  padding-inline: 0;
}

.content-w-aside__content .base-section:first-child {
  padding-top: 0;
}

.content-w-aside__content .base-section:last-child {
  padding-bottom: 0;
}

@media (min-width: 62em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__row {
  gap: 4rem 2rem;
}

@media (min-width: 62em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3 / span 8;
}

@media (min-width: 62em) {
  .content-w-aside__aside {
    grid-column: span 4 / -1;
  }
}

@media (min-width: 93.75em) {
  .content-w-aside__aside {
    grid-column: span 3 / -1;
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: .0625rem solid var(--color);
  border-radius: var(--radius);
  background-color: #fff;
  transition: background-color .3s;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  background-color: #fff;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual:after, .f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: #171429;
  --radius: .25rem;
  --size: 1.5rem;
  cursor: pointer;
}

.f-checkbox.--disabled {
  --color: #a9a9b2;
}

.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox.--radio .f-checkbox__visual:after {
  display: block;
}

.f-checkbox-select__header {
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--header-background);
  border: .0625rem solid #ebebeb;
  min-height: 3rem;
  padding: .4375rem .5rem;
}

.f-checkbox-select__clear {
  display: none;
}

.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: background-color .3s;
}

.f-checkbox-select__toggler svg {
  width: .875rem;
  height: .875rem;
  transition: scale .3s;
}

.f-checkbox-select__toggler:hover {
  background-color: #ebebeb;
}

.f-checkbox-select__label {
  --end: 0;
  flex: 1;
  height: 2rem;
  top: 0;
  left: 0;
  overflow: hidden;
}

.f-checkbox-select__label > span {
  white-space: nowrap;
  transition: left 2s linear;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.f-checkbox-select__label:after {
  content: "";
  pointer-events: none;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 1rem;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.f-checkbox-select__label:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  transform-origin: top;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  will-change: opacity, transform;
  background-color: #fff;
  border: .0625rem solid #a9a9b2;
  width: calc(100% - .0625rem);
  transition: opacity .3s, transform .3s;
  top: calc(100% - .0625rem);
  left: 50%;
  transform: translateX(-50%)scaleY(.25);
}

.f-checkbox-select__body-inner {
  max-height: 10rem;
}

.f-checkbox-select {
  --header-background: #fff;
  min-width: 0;
}

.f-checkbox-select.--active {
  --header-background: #e7f0ff;
}

.f-checkbox-select.--active .f-checkbox-select__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%)scaleY(1);
}

.f-checkbox-select.--active .f-checkbox-select__toggler svg {
  scale: -1;
}

.f-date__icon {
  display: var(--icon-display);
  pointer-events: none;
  padding: .5rem 1rem .5rem .5rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date {
  --border-color: #a9a9b2;
  --radius: .25rem;
  --icon-display: block;
  --cursor: pointer;
}

.f-date:not(.--native) .f-date__input {
  padding-right: 3rem;
}

.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}

.f-date.--invalid {
  --border-color: #d8092e;
}

.f-date__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-error {
  --display: none;
  display: var(--display);
  z-index: 2;
  right: .5rem;
}

.f-error.--show {
  --display: flex;
}

.f-base {
  color: #171429;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  background-clip: padding-box;
  border: none;
  width: 100%;
  min-height: 3.25rem;
  padding: 1rem 2rem 1rem .5rem;
  display: block;
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #171429;
}

textarea.f-base {
  min-height: 5.75rem;
  padding-right: 2.5rem;
}

textarea.f-base::-webkit-resizer {
  background-image: url("drag.e50b2723.svg");
  background-position: 25% 75%;
  background-repeat: no-repeat;
  width: 1rem;
  height: 1rem;
  transform: translateX(-2rem);
}

.i-selected-file {
  cursor: pointer;
  background-color: #e7f0ff;
  min-height: 1.5rem;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files, .f-file__input {
  display: none;
}

.f-label {
  --top: auto;
  --bottom: 50%;
  --translate-y: 50%;
  --color: #171429;
  top: var(--top);
  bottom: var(--bottom);
  transform: translateY(var(--translate-y));
  color: var(--color);
  pointer-events: none;
  z-index: 2;
  background-color: #dfbe81;
  margin-right: .0625rem;
  padding-inline: .5rem;
  font-size: 1rem;
  line-height: 1.25rem;
  transition: transform .3s, color .3s, font-size .3s, top .3s, bottom .3s, left .3s;
  position: absolute;
  left: 0;
}

.f-label.--required:after {
  content: "*";
  color: var(--color);
  padding-left: .25rem;
}

.f-label.--active {
  --bottom: 0;
  font-size: .75rem;
  line-height: .875rem;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
}

.f-single-select__select.slim-select.ss-main:focus {
  box-shadow: none;
}

.f-single-select__select.slim-select.ss-values.ss-single {
  margin: 0;
}

.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 1rem;
}

.f-single-select, .f-single-select__select.slim-select {
  --radius: .25rem;
  --height: 3rem;
  --padding-inline: 1rem;
  --border-color: #a9a9b2;
  --ss-primary-color: #171429;
  --ss-bg-color: #fff;
  --ss-font-color: #081828;
  --ss-font-placeholder-color: #a9a9b2;
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 18.75rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .3125rem;
  --ss-spacing-s: .1875rem;
  --ss-animation-timing: .2s;
  --ss-border-radius: var(--radius);
}

.f-single-select.--native .f-single-select__wrap, .f-single-select__select.slim-select.--native .f-single-select__wrap {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.f-single-select.--native .f-single-select__wrap:after, .f-single-select__select.slim-select.--native .f-single-select__wrap:after {
  content: "";
  pointer-events: none;
  background-image: url("chevron-down.76292987.svg");
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: select;
  justify-self: end;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  display: block;
}

.f-single-select.--native .f-single-select__select, .f-single-select__select.slim-select.--native .f-single-select__select {
  border-radius: var(--radius);
  min-height: var(--height);
  border: .0625rem solid var(--border-color);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  outline: none;
  grid-area: select;
  width: 100%;
  margin: 0;
  padding-left: 1rem;
}

.f-single-select.--invalid, .f-single-select__select.slim-select.--invalid, .f-single-select.--invalid .ss-main, .f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #d8092e;
}

.f-switch {
  --width: 3rem;
  --height: 1.5rem;
  --offset: .25rem;
  --background: #7387ac;
  --background-checked: #171429;
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #fff;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #a9a9b2;
  --background-checked: #a9a9b2;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}

.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-text__icon {
  pointer-events: none;
  padding: .5rem;
  position: absolute;
}

.f-text__icon.--before {
  padding-left: 1rem;
  top: 50%;
  left: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__icon.--after {
  padding-right: 1rem;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--color);
  transition: color .3s;
}

.f-text {
  --color: #171429;
  --border-color: #171429;
  --radius: 0;
}

.f-text.--invalid {
  --color: #d8092e;
  --border-color: #d8092e;
}

.f-text.--invalid .f-label {
  --color: #d8092e;
}

.f-text.--icon-before .f-text__input {
  padding-left: 3rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 3rem;
}

.f-text__wrap {
  border-bottom: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --color: #171429;
  --border-color: #171429;
  --radius: 0;
}

.f-textarea.--invalid {
  --color: #d8092e;
  --border-color: #d8092e;
}

.f-textarea .f-label {
  --top: 1.5rem;
  --bottom: auto;
  --translate-y: -50%;
}

.f-textarea .f-label.--active {
  --top: 100%;
}

.f-textarea__wrap {
  border-bottom: .0625rem solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
  color: var(--color);
  transition: color .3s;
}

.f-contact {
  --padding: 1.5rem;
  padding: var(--padding);
  background: #dfbe81;
}

@media (min-width: 48em) {
  .f-contact {
    --padding: 2.5rem;
  }

  .f-contact__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 93.749em) {
  .f-contact__submit {
    margin-left: auto;
  }
}

@media (max-width: 35.999em) {
  .f-contact__submit {
    width: 100%;
  }
}

.f-contact__terms a {
  color: #171429;
  font-weight: 600;
}

@media (min-width: 93.75em) {
  .f-contact__terms {
    margin-left: auto;
  }
}

.f-newsletter {
  background-color: #e7f0ff;
  border-radius: 1rem;
}

@media (min-width: 62em) {
  .f-newsletter__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-newsletter__header {
  gap: .25rem;
}

@media (max-width: 35.999em) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (max-width: 47.999em) {
  .f-newsletter__submit {
    width: 100%;
  }
}

.g-base {
  flex-wrap: wrap;
  display: flex;
}

.g-base.--gap-xs {
  gap: .25rem;
}

.g-base.--gap-sm {
  gap: .5rem;
}

.g-base.--gap-md {
  gap: 1rem;
}

.g-base.--gap-lg {
  gap: 1.5rem;
}

.g-base.--gap-xl {
  gap: 2rem;
}

.g-base.--align-left {
  justify-content: flex-start;
}

.g-base.--align-right {
  justify-content: flex-end;
}

.g-base.--align-center {
  justify-content: center;
}

.g-base.--align-between {
  justify-content: space-between;
}

.g-buttons {
  --spacing: 4.5rem;
  margin-top: var(--spacing);
}

.g-buttons.--spacing-sm {
  --spacing: 3rem;
}

.g-buttons.--spacing-xs {
  --spacing: 1.5rem;
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

@media (min-width: 75em) {
  .g-events {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-hero-slider__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-hero-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-hero-slider__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 35.999em) {
  .g-hero-slider__button {
    --offset: .5rem;
  }
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-hero-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider__buttons {
  pointer-events: none;
}

.g-jobs__inner-row {
  margin: -.0625rem;
}

.g-jobs__cell {
  --border: .0625rem solid #171429;
  border: var(--border);
}

.g-posts-grid {
  gap: 4.5rem 2rem;
  display: grid;
}

@media (min-width: 62em) {
  .g-posts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell:first-of-type {
  grid-column: 1 / -1;
}

.g-posts {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem 2rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-posts {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 47.999em) {
  .g-posts.--compact {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--inline {
  flex-direction: column;
  display: flex;
}

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: #e7f0ff;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}

.g-reviews-slider__button {
  pointer-events: auto;
}

@media (max-width: 35.999em) {
  .g-reviews-slider__button.btn.--icon-only {
    --min-height: 2.5rem;
  }
}

@media (max-width: 359px) {
  .g-reviews-slider__button.btn.--icon-only {
    --min-height: 2rem;
  }

  .g-reviews-slider__button.btn.--icon-only .icon.--lg {
    --size: 1.25rem;
  }
}

.g-reviews-slider.swiper {
  overflow: initial;
  align-items: center;
  height: 100%;
  display: flex;
}

.g-reviews-slider .swiper-wrapper {
  display: flex;
}

.g-reviews-slider .swiper-slide {
  box-sizing: border-box;
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  height: auto;
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

.g-reviews-slider .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
}

.g-reviews-slider__buttons:before {
  content: "";
  z-index: 1;
  pointer-events: none;
  background-color: #fff;
  width: 26rem;
  height: .0625rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(calc(5rem - 100%), -50%);
}

@media (min-width: 48em) and (max-width: 93.749em) {
  .g-signpost.--cols-4 {
    max-width: 44.875rem;
    margin-inline: auto;
  }
}

@media (max-width: 47.999em) {
  .g-signpost.--cols-4 {
    max-width: 22.5rem;
    margin-inline: auto;
  }

  .g-signpost.--cols-3 {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.g-steps {
  row-gap: 5rem;
}

.g-steps__cell:last-child .i-step:before {
  display: none;
}

@media (min-width: 75em) {
  .g-steps__cell:nth-child(4) .i-step:before {
    --left: 0;
    width: calc(100% + 3.5rem);
  }

  .g-steps__cell:nth-child(-n+6):nth-child(n+4) .i-step {
    --count-left: 0;
    --count-right: auto;
    --transform-count: translate(-50%, -50%);
    --transform-count-bg: translate(-50%, -50%) rotate(45deg);
  }
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (min-width: 93.75em) {
  .g-usps__cell {
    width: min(100%, 24.5rem);
  }
}

.icon {
  --size: 1.25rem;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
}

.icon.--primary {
  --color: #dfbe81;
}

.icon.--secondary {
  --color: #171429;
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #081828;
}

.icon.--danger {
  --color: #d8092e;
}

.icon.--4xl {
  --size: 3.625rem;
}

.icon.--xxxl {
  --size: 3rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.25rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .625rem;
}

.icon.--xxs {
  --size: .5rem;
}

.img {
  overflow: hidden;
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--contain picture, .img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--cover picture {
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  position: relative;
}

.img.--placeholder:before {
  content: "";
  z-index: -1;
  opacity: .8;
  background-color: #171429;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-alert {
  --background: #fff;
  --border-color: #ebebeb;
  --color: #081828;
  --opacity: 0;
  --translate: -2rem;
  --icon-background: #fff;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  will-change: opacity, transform;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
  transition: opacity .3s, transform .3s;
}

.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}

.i-alert.--success {
  --border-color: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
  --icon-background: #48a463;
}

.i-alert.--danger {
  --border-color: #d8092e;
  --background: #f9dede;
  --color: #d8092e;
  --icon-background: #d8092e;
}

.i-alert.--warning {
  --border-color: #fdb81e;
  --background: #fff1d2;
  --color: #fdb81e;
  --icon-background: #fdb81e;
}

.i-alert.--primary {
  --border-color: #171429;
  --background: #171429;
  --color: #fff;
  --icon-background: #171429;
}

.i-alert__icon, .i-alert__close {
  background-color: var(--icon-background);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author {
  display: flex;
}

.i-author.--compact {
  align-items: center;
}

.i-author.--compact .i-author__description {
  display: none;
}

@media (max-width: 35.999em) {
  .i-author {
    flex-direction: column;
  }
}

.i-author__image {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-banner__header {
  max-width: 80ch;
}

.i-banner__image {
  z-index: -1;
  width: 100%;
  height: 100%;
}

.i-banner__description {
  line-height: 1.5rem;
}

.i-banner {
  background-color: #e7f0ff;
}

@media (max-width: 35.999em) {
  .i-banner {
    padding: 2rem;
  }
}

.i-banner.--image {
  background-color: #0000;
}

@media (min-width: 48em) {
  .i-banner.--image .i-banner__inner {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.i-banner.--image .i-banner__description {
  display: none;
}

.i-banner.--image .i-banner__buttons {
  margin-top: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-document {
  color: #e7f0ff;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
}

.i-document__title, .i-document__details {
  color: #171429;
}

@media (max-width: 47.999em) {
  .i-event {
    flex-direction: column;
    max-width: 20rem;
    margin-inline: auto;
  }
}

@media (min-width: 48em) {
  .i-event__image-link {
    width: 17.5rem;
    height: 13.125rem;
  }
}

.i-faq__body {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  background-color: #e7f0ff;
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
}

.i-faq__body-inner {
  position: relative;
  overflow: hidden;
}

.i-faq__body-inner:before {
  content: "";
  background-color: #7387ac;
  width: 100%;
  height: .0625rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq {
  --border-radius: .375rem;
  border-radius: var(--border-radius);
  border: .0625rem solid #7387ac;
}

.i-faq svg {
  will-change: transform;
  transition: transform .3s;
}

.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}

.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-hero__body {
  --offset-left: 0;
  --offset-right: 0;
  --order: 1;
  padding-left: var(--offset-left);
  padding-right: var(--offset-right);
  order: var(--order);
}

.i-hero__body:before, .i-hero__body:after {
  content: "";
  background-color: #fff;
  position: absolute;
}

.i-hero__body:before {
  z-index: 1;
  width: 26rem;
  height: .0625rem;
  top: 4rem;
  right: 0;
  transform: translateX(5.25rem);
}

.i-hero__body:after {
  width: 15rem;
  height: .0625rem;
  bottom: 8.75rem;
  left: 0;
  transform: translateX(-2.5rem);
}

@media (min-width: 62em) {
  .i-hero__body {
    --offset-left: 1.5rem;
    --offset-right: 3rem;
    --order: 0;
  }
}

@media (max-width: 93.749em) {
  .i-hero__body:before, .i-hero__body:after {
    display: none;
  }
}

@media (min-width: 62em) {
  .i-hero {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.i-hero__img img {
  aspect-ratio: 1 / .698;
}

.i-hero__img-decor {
  transform: translate(3.5rem, 3.5rem);
}

@media (min-width: 62em) {
  .i-hero__header {
    max-width: 39.1875rem;
  }
}

.i-hero__header .base-heading.u-h1 {
  text-wrap: pretty;
}

@media (max-width: 93.749em) {
  .i-hero__header .base-heading.u-h1 {
    --size: 3rem;
  }
}

@media (max-width: 74.999em) {
  .i-hero__header .base-heading.u-h1 {
    --size: 2rem;
  }
}

@media (min-width: 62em) {
  .i-hero__description {
    max-width: 40.375rem;
  }
}

.i-hero-slide__body {
  --radius: .5rem;
  border-radius: var(--radius);
  -webkit-backdrop-filter: blur(1.25rem);
  backdrop-filter: blur(1.25rem);
  max-width: 35.5rem;
}

.i-hero-slide__body:before {
  content: "";
  z-index: -1;
  opacity: .4;
  border-radius: var(--radius);
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 61.999em) {
  .i-hero-slide__body {
    gap: .5rem;
    max-width: 100%;
    position: static;
    left: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 35.999em) {
  .i-hero-slide__body {
    padding: 1rem;
  }
}

.i-hero-slide {
  --offset: 4rem;
}

.i-hero-slide.--left .i-hero-slide__body {
  left: var(--offset);
}

.i-hero-slide.--right .i-hero-slide__body {
  right: var(--offset);
}

@media (min-width: 62em) {
  .i-hero-slide.--center .i-hero-slide__body {
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@media (max-width: 61.999em) {
  .i-hero-slide {
    padding: 1rem;
  }

  .i-hero-slide__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.i-job {
  --padding-x: 1.5rem;
  --padding-y: 2rem;
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
}

.i-job__icon {
  width: 1.25rem;
  height: 1.25rem;
}

.i-media.--video {
  cursor: pointer;
}

.i-menu {
  --padding: .5rem;
  --color: #fff;
  --background: transparent;
  --opacity: 0;
}

.i-menu.--current {
  --opacity: 1;
}

.i-menu__link {
  padding: var(--padding);
  color: var(--color);
  background-color: var(--background);
  min-height: 2.5rem;
  text-decoration: none;
  transition: background-color .3s;
}

.i-menu__link:hover {
  --background: #211e33;
  color: var(--color);
}

.i-menu__link:focus {
  color: var(--color);
}

.i-menu__icon {
  opacity: var(--opacity);
  pointer-events: none;
  transition: opacity .3s;
  transform: translate(-50%, -.625rem);
}

.i-notification {
  --min-height: 3rem;
  min-height: var(--min-height);
}

@media (min-width: 75em) {
  .i-notification {
    --min-height: 2.75rem;
    padding-bottom: .25rem;
  }
}

@media (max-width: 35.999em) {
  .i-notification {
    font-size: .75rem;
  }
}

.i-notification__container {
  width: min(100% - 2rem, 88rem);
}

@media (max-width: 35.999em) {
  .i-notification__container {
    gap: .5rem;
  }

  .i-notification__body {
    align-items: flex-start;
  }
}

.i-person {
  border: .0625rem solid #171429;
}

.i-person__title {
  word-break: break-word;
}

.i-person__email {
  word-break: break-all;
}

@media (min-width: 62em) {
  .i-post.--hero {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero {
    max-width: 43rem;
    margin-inline: auto;
  }
}

.i-post.--hero .i-post__tags {
  margin-top: 0;
}

@media (min-width: 62em) {
  .i-post.--hero .i-post__content-col {
    padding-block: 2rem;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (min-width: 62em) {
  .i-post.--inline {
    grid-template-columns: minmax(10rem, 27.875rem) auto;
    gap: 2rem;
    display: grid;
  }

  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (max-width: 61.999em) {
  .i-post {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan {
  border: .0625rem solid #e7f0ff;
  border-radius: 1.5rem;
}

.i-priceplan.--highlight {
  border: none;
  box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
}

@media (max-width: 61.999em) {
  .i-priceplan {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  color: #211e33;
  background: #e7f0ff;
  border-radius: .5rem;
  width: calc(100% - 4rem);
  top: -1rem;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 4rem;
}

.i-rating .base-heading:before {
  display: none;
}

.i-rating.--vertical {
  flex-direction: row-reverse;
}

@media (min-width: 62em) {
  .i-rating.--vertical {
    flex-direction: column;
  }
}

.i-rating.--horizontal {
  flex-direction: row-reverse;
  gap: 1rem;
}

.i-rating.--horizontal .base-heading {
  font-size: 1.5rem;
}

.i-reviews-slide {
  --transform: scale(1);
}

.i-reviews-slide.--active {
  --transform: scale(-1);
}

.i-reviews-slide__content.entry-content {
  line-height: 1.25rem;
  transition: max-height .3s;
}

.i-reviews-slide__content.entry-content p:last-child {
  margin-bottom: 0;
}

.i-reviews-slide__footer {
  border-left: .0625rem solid #fff;
  padding-block: .375rem;
}

.i-reviews-slide__profile {
  gap: .375rem;
}

.i-reviews-slide__more:before {
  content: "";
  background-color: #dfbe81;
  width: 100%;
  height: .0625rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.i-reviews-slide__more-text {
  gap: .25rem;
}

.i-reviews-slide__more-text svg {
  transform: var(--transform);
}

.i-step {
  --size: 6rem;
  --padding-x: 2rem;
  --left: var(--padding-x);
  --count-size: 1.8125rem;
  --count-left: auto;
  --count-right: 0;
  --transform-count: translate(50%, -50%);
  --transform-count-bg: translate(50%, -50%) rotate(45deg);
  padding-inline: var(--padding-x);
}

.i-step:before {
  content: "";
  left: var(--left);
  top: calc(var(--size) / 2);
  background-color: #fff;
  width: 100%;
  height: .0625rem;
  display: block;
  position: absolute;
  transform: translate(0, -50%);
}

@media (max-width: 47.999em) {
  .i-step:before {
    display: none;
  }
}

.i-step .i-step__icon {
  width: var(--size);
  height: var(--size);
}

.i-step .i-step__count {
  left: var(--count-left);
  right: var(--count-right);
  transform: var(--transform-count);
}

.i-step .i-step__count-bg {
  width: var(--count-size);
  height: var(--count-size);
  left: var(--count-left);
  right: var(--count-right);
  transform: var(--transform-count-bg);
}

@media (min-width: 93.75em) {
  .i-step {
    --padding-x: 3.5rem;
  }
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .25rem;
  --min-height: 2rem;
  --font-size: 1rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  font-size: var(--font-size);
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: .125rem;
  --min-height: 1.5rem;
  --font-size: .875rem;
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 2.5rem;
}

.i-tag {
  --font-color: #171429;
  --background-color: #e7f0ff;
  background-color: var(--background-color);
  color: var(--font-color);
  border: .0625rem solid var(--background-color);
  border-radius: .25rem;
}

.i-tag.--outline {
  border-color: #7387ac;
}

.i-tag.--blank {
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

a.i-tag, .i-tag.--hoverable {
  cursor: pointer;
  text-decoration: none;
  transition: border-color .3s;
}

a.i-tag:hover, .i-tag.--hoverable:hover {
  border-color: #171429;
}

a.i-tag.--blank {
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s;
}

a.i-tag.--blank:hover {
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
  border-color: #0000;
}

.i-usp {
  --min-height: 6rem;
}

@media (min-width: 36em) {
  .i-usp {
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
  }

  .i-usp .i-usp__body {
    align-items: flex-start;
  }
}

.i-usp__icon {
  width: var(--min-height);
  height: var(--min-height);
  background-color: #211e33;
}

.i-usp__heading {
  align-items: center;
  display: flex;
}

@media (min-width: 36em) {
  .i-usp__heading {
    min-height: var(--min-height);
  }
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__drowpdown {
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  background-color: #211e33;
  padding: 0 .25rem .25rem;
  transition: opacity .3s;
  top: 100%;
  left: 0;
}

.c-language-select__active {
  gap: .25rem;
  min-height: 2.5rem;
  padding-left: 1rem;
  padding-right: .75rem;
}

.c-language-select__dropdown-link {
  color: #fff;
  text-decoration: none;
  transition: background-color .3s;
}

.c-language-select__dropdown-link:hover {
  color: #fff;
  background-color: #171429;
}

.c-language-select__dropdown-link:focus {
  color: #fff;
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-right:hover svg {
  transform: translateX(.5rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  color: #081828;
  text-decoration-color: #0000;
}

.link.--black:hover, .link.--black:focus {
  color: #081828;
  text-decoration-color: #081828;
}

.link.--primary {
  color: #dfbe81;
  text-decoration-color: #0000;
}

.link.--primary:hover, .link.--primary:focus {
  color: #dfbe81;
  text-decoration-color: #dfbe81;
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  font-weight: 500;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: #0000;
}

.link.--font-family-secondary {
  font-family: Libre Baskerville, serif;
}

@media (max-width: 35.999em) {
  .main-footer-column {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__content.entry-content p:last-child {
  margin-bottom: 0;
}

.main-footer-column__logo {
  max-width: 8.625rem;
  max-height: 5rem;
}

.main-footer-column__socials {
  margin-top: 4rem;
}

.main-footer__contact-icon {
  width: 4.5rem;
  height: 4.5rem;
}

.main-footer__mail {
  word-break: break-all;
}

.main-footer-column__title {
  font-weight: 700;
}

.main-footer-column__item {
  --opacity: 0;
}

.main-footer-column__item.--current {
  --opacity: 1;
}

.main-footer-column__link {
  --color: #fff;
  --background: transparent;
  color: var(--color);
  background-color: var(--background);
  text-decoration: none;
  transition: background-color .3s;
}

.main-footer-column__link:hover {
  --background: #211e33;
  color: var(--color);
}

.main-footer-column__link:focus {
  color: var(--color);
}

.main-footer-column__icon {
  opacity: var(--opacity);
  pointer-events: none;
  transition: opacity .3s;
  transform: translate(-50%, -.625rem);
}

@media (min-width: 36em) {
  .main-footer__columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 75em) {
  .main-footer__columns {
    grid-template-columns: repeat(3, 1fr) 15rem;
  }
}

.main-footer__top {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

@media (max-width: 35.999em) {
  .main-footer__top {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}

.m-header-logo img {
  max-height: 5rem;
}

@media (max-width: 74.999em) {
  .m-header-logo img {
    max-height: 4rem;
  }
}

@media (max-width: 35.999em) {
  .m-header-logo {
    max-width: 7.5rem;
  }

  .m-header-logo img {
    max-height: 3.5rem;
  }
}

.m-header-search {
  --translate-y: -150%;
  --pointer-events: none;
  --opacity: 0;
}

.m-header-search.--open {
  --translate-y: -50%;
  --pointer-events: auto;
  --opacity: 1;
}

.m-header-search__form {
  z-index: 2;
  transform: translateY(var(--translate-y));
  pointer-events: var(--pointer-events);
  opacity: var(--opacity);
  will-change: transform, opacity;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 50%;
  left: 0;
}

.m-header {
  --shadow-color: transparent;
  --min-height: 8rem;
  --padding-y: 1.5rem;
  z-index: 5;
  box-shadow: 0 .25rem 2.5rem 0 var(--shadow-color);
  background-color: #171429;
  transition: box-shadow .3s, transform .3s;
  position: sticky;
  top: 0;
  left: 0;
}

.m-header.headroom--not-top {
  --shadow-color: #171429;
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--padding-y);
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .m-header__body {
    --min-height: 7.75rem;
    --padding-y: 1rem;
  }
}

@media (max-width: 74.999em) {
  .m-header__body {
    --min-height: 5.5rem;
    --padding-y: .25rem;
  }
}

.m-header__strip {
  background: #dfbe81;
  width: 100%;
  max-width: 100%;
  height: .25rem;
}

.m-nav {
  --transition-timing: 0;
}

@media (min-width: 62em) {
  .m-nav {
    margin-left: auto;
  }
}

@media (max-width: 61.999em) {
  .m-nav {
    --shadow: transparent;
    box-shadow: var(--shadow);
    transition: var(--transition-timing) ease;
    z-index: 100;
    background-color: #171429;
    width: 100%;
    max-width: 26.25rem;
    height: 100%;
    transition-property: transform, box-shadow;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden auto;
    transform: translateX(100%);
  }

  .m-nav.--open {
    --shadow: 0 .25rem 2.5rem 0 #171429;
    transform: translateX(0);
  }

  .m-nav.--transition {
    --transition-timing: .3s;
  }
}

@media (min-width: 62em) {
  .m-nav__list {
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 61.999em) {
  .m-nav__list {
    padding-inline: .5rem;
  }
}

.c-modal__dialog {
  pointer-events: none;
  cursor: cell;
  width: auto;
  margin: 2rem auto;
  position: relative;
}

.c-modal__content {
  width: var(--width);
  pointer-events: auto;
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  margin-inline: auto;
  position: relative;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem #0f26391a;
}

.c-modal__body {
  flex: auto;
  padding: 2rem 1.5rem;
  position: relative;
}

.c-modal__header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 0;
  display: flex;
}

.c-modal__button-container {
  order: -1;
  justify-content: flex-end;
  display: flex;
}

.c-modal__close-cross {
  transform: translate(-1rem, 1rem);
}

.c-modal__footer {
  padding: 0 1.5rem 2rem;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 1.375rem;
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 45rem);
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  background-color: #00000040;
  outline: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 30rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 71.25rem);
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 1;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  position: var(--position);
  background-color: #081828;
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .75;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 2.5rem;
  background-color: var(--background);
  min-height: var(--size);
  border-radius: .5rem;
  padding: .5rem 1rem;
  transition-property: text-decoration-color, color, background-color;
}

.link.c-pagination__link:hover {
  --background: #171429;
}

.link.c-pagination__link.--page-number {
  min-width: var(--size);
  padding: .5rem;
  text-decoration: none;
}

.link.c-pagination__link.--active {
  --background: #171429;
  pointer-events: none;
  color: #fff;
}

.link.c-pagination__link.--blank {
  pointer-events: none;
}

@media (max-width: 47.999em) {
  .c-pagination__items {
    flex-basis: 100%;
    order: -1;
    justify-content: center;
  }

  .c-pagination {
    justify-content: center;
  }
}

.c-scroll-up {
  --size: 2.5rem;
  --offset: 2.5rem;
  --radius: 0;
  --background: transparent;
  --background-hover: #171429;
  --icon-size: 1rem;
  --icon-color: #fff;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border-radius: var(--radius);
  background-color: var(--background);
  z-index: 50;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(.8);
  cursor: pointer;
  border: .0625rem solid #171429;
  transition: background-color .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
}

.c-scroll-up:hover {
  --background: var(--background-hover);
}

.c-scroll-up:after {
  content: "";
  opacity: 0;
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #171429;
}

.c-scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #171429;
}

.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
  rotate: -90deg;
}

.c-scroll-up path {
  fill: var(--icon-color);
}

.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-calendar__app {
  --fc-border-color: #ebebeb;
  --fc-today-bg-color: #e7f0ff;
  --fc-event-bg-color: #171429;
  --fc-button-bg-color: #171429;
  --fc-button-border-color: #171429;
  --fc-button-active-bg-color: #211e33;
  --fc-button-active-border-color: #211e33;
  --fc-button-hover-bg-color: #211e33;
  --fc-button-hover-border-color: #211e33;
  height: 35.625rem;
}

.s-calendar__app.fc {
  height: auto;
}

.s-calendar__app.--close {
  display: none;
}

.s-calendar__app .fc-daygrid {
  background-color: #fff;
}

.s-calendar__app .fc-event {
  border: none;
  border-radius: 0;
}

.s-calendar__app .fc-daygrid-day-number {
  color: #171429;
  text-decoration: none;
}

.s-calendar__app .fc-event-time {
  flex-shrink: 0;
}

.s-calendar__app .fc-col-header-cell-cushion {
  text-decoration: none;
}

.s-calendar__app .fc-col-header-cell {
  padding: .5rem;
}

.s-calendar__toggler.--close svg {
  transform: scale(-1);
}

@media (min-width: 62em) {
  .s-contact-form__row {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 75em) {
  .s-contact-form__row {
    grid-template-columns: 3fr 4fr;
  }
}

@media (min-width: 93.75em) {
  .s-contact-form__row {
    grid-template-columns: 1fr 2fr;
  }
}

.s-contact-form__contact {
  --padding-x: 1.5rem;
  padding-inline: var(--padding-x);
  gap: 3.5rem;
}

.s-contact-form__contact:before {
  content: "";
  z-index: 1;
  background-color: #fff;
  width: .0625rem;
  height: 5.5rem;
  position: absolute;
  bottom: 0;
  left: 7.5rem;
  transform: translate(-50%, 3.25rem);
}

@media (max-width: 61.999em) {
  .s-contact-form__contact:before {
    display: none;
  }
}

@media (min-width: 48em) {
  .s-contact-form__contact {
    --padding-x: 2.5rem;
  }
}

@media (min-width: 75em) {
  .s-contact-form__contact {
    --padding-x: 4.5rem;
  }
}

.s-contact-form__contact-icon {
  width: 6rem;
  height: 6rem;
}

.s-contact-form__mail {
  word-break: break-all;
}

.s-error-404__heading {
  --font-size: 11rem;
  font-size: var(--font-size);
}

@media (max-width: 47.999em) {
  .s-error-404__heading {
    --font-size: 8.5rem;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 1.5rem;
}

.s-error-404__perex {
  text-align: center;
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 0;
}

@media (min-width: 62em) {
  .s-hero .container {
    max-width: 123.25rem;
    padding-inline: 0;
  }
}

.s-jobs__footer {
  background-color: #e7f0ff;
}

@media (min-width: 48em) {
  .s-jobs__footer {
    padding: 3rem 4rem;
  }
}

@media (max-width: 35.999em) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }

  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }

  .s-post-detail__share {
    flex-direction: column;
  }
}

.s-separator {
  --color: #171429;
  --icon-bg: #211e33;
}

.s-separator.--color-primary-400 {
  --color: #211e33;
}

.s-separator__wrap {
  height: 3.625rem;
}

.s-separator__line {
  background-color: var(--color);
  pointer-events: none;
  width: 100%;
  max-width: 88rem;
  height: .0625rem;
}

.s-separator__icon {
  box-shadow: 0 0 0 .0625rem var(--color);
  background-color: var(--icon-bg);
  pointer-events: none;
  width: 3.625rem;
  min-height: 3.625rem;
}

.s-steps__img {
  width: 22rem;
}

@media (max-width: 93.749em) {
  .s-steps__img {
    width: 15rem;
  }
}

.s-steps__side-img img {
  aspect-ratio: 1 / 1.997;
}

.s-steps__img-decor {
  transform: translate(-2rem, 2rem);
}

@media (min-width: 62em) {
  .s-text-media__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-text-media__col.--content-col {
  order: var(--content-order);
  padding-left: var(--content-offset-left);
  padding-right: var(--content-offset-right);
}

.s-text-media__img-decor {
  transform: translate(-2rem, 2.75rem);
}

@media (max-width: 74.999em) {
  .s-text-media__img-decor {
    display: none;
  }
}

.s-text-media {
  --content-offset-left: 2.5rem;
  --content-offset-right: 0;
  --content-order: 1;
}

@media (max-width: 61.999em) {
  .s-text-media {
    --content-offset-left: 0;
  }
}

.s-text-media.--reverse {
  --content-offset-left: 0;
  --content-offset-right: 2.5rem;
}

@media (min-width: 62em) {
  .s-text-media.--reverse {
    --content-order: -1;
  }
}

@media (max-width: 61.999em) {
  .s-text-media.--reverse {
    --content-offset-right: 0;
  }
}

.s-text-media.--reverse .s-text-media__img-decor {
  left: auto;
  right: 0;
  transform: translate(2rem, 2.75rem);
}

.widget_categories select {
  --border-color: #a9a9b2;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  width: 100%;
  height: 3rem;
  margin: 0;
  padding: .5rem;
}

.wp-aside-widgets .cat-item {
  align-items: center;
  padding-right: .5rem;
  display: flex;
}

.wp-aside-widgets .cat-item > a {
  width: 100%;
}

.widget_media_image a {
  display: block;
}

.widget_nav_menu .menu-item-has-children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_nav_menu .menu-item-has-children .sub-menu {
  padding-left: 1rem;
}

.widget_nav_menu .menu-item-has-children > .sub-menu {
  display: var(--submenu-display);
}

.widget_nav_menu .menu-item-has-children > a {
  padding-right: 3rem;
}

.widget_nav_menu .menu-item-has-children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_pages .page_item.active {
  text-decoration: underline;
}

.widget_pages .page_item_has_children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_pages .page_item_has_children .sub-menu {
  padding-left: 1rem;
}

.widget_pages .page_item_has_children > .children {
  display: var(--submenu-display);
}

.widget_pages .page_item_has_children > a {
  padding-right: 3rem;
}

.widget_pages .page_item_has_children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_search form > div {
  gap: .5rem;
  display: flex;
}

.widget_search input[type="text"] {
  --border-color: #a9a9b2;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  width: 100%;
  min-height: 2.875rem;
  padding-left: .5rem;
  display: block;
}

.widget_search input[type="text"]:focus {
  border-color: none;
  outline: none;
}

.widget_search input[type="submit"] {
  --border-radius: .375rem;
  --background: #171429;
  --font-color: #fff;
  color: var(--font-color);
  background-color: var(--background);
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  height: 100%;
  min-height: 2.875rem;
  padding-block: .5rem;
  padding-inline: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
}

.widget_search input[type="submit"]:hover {
  color: var(--font-color);
  text-decoration: none;
}

.widget_search input[type="submit"]:focus, .widget_search input[type="submit"]:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

@media (hover: hover) {
  .widget_search input[type="submit"]:hover {
    --background: #211e33;
  }
}

.wp-aside-widgets ul {
  flex-direction: column;
  display: flex;
}

.wp-aside-widgets .widget {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (max-width: 61.999em) {
  .wp-aside-widgets .widget {
    --content-display: none;
    --svg-rotate: 0deg;
  }

  .wp-aside-widgets .widget ul, .wp-aside-widgets .widget form {
    display: var(--content-display);
  }

  .wp-aside-widgets .widget.--open {
    --content-display: flex;
    --svg-rotate: 180deg;
  }
}

.wp-aside-widgets .widgettitle {
  --size: 1.5rem;
  font-size: var(--size);
  font-weight: 700;
  display: block;
  position: relative;
}

@media (max-width: 47.999em) {
  .wp-aside-widgets .widgettitle {
    --size: 1.25rem;
  }
}

.wp-aside-widgets .wp-caption {
  flex-direction: column;
  gap: .5rem;
  max-width: 100%;
  margin: 0;
  display: flex;
}

.wp-aside-widgets li {
  --color: #081828;
  display: block;
}

.wp-aside-widgets li > a {
  padding-block: .5rem;
  color: var(--color);
  min-height: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-decoration-color: #0000;
  transition: text-decoration-color .3s;
  display: block;
}

.wp-aside-widgets li > a:hover {
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
}

.widget__dropdown-btn {
  --size: 1.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.widget__dropdown-btn svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

@media (min-width: 62em) {
  .widget__dropdown-btn {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 2.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.wp-aside-widgets .sub-menu__button svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

.wp-breadcrumbs {
  --gap: 1.5rem;
}

.wp-breadcrumbs__group > span {
  gap: var(--gap);
  display: flex;
}

@media (max-width: 47.999em) {
  .wp-breadcrumbs__group {
    display: none;
  }
}

.wp-breadcrumbs__group > span span {
  --height: 3.5rem;
  min-height: var(--height);
  align-items: center;
  display: flex;
}

.wp-breadcrumbs__group > span span a {
  display: block;
}

.wp-breadcrumbs__group > span span:first-child a {
  color: #0000;
  height: var(--height);
  -webkit-user-select: none;
  user-select: none;
  background-image: url("home.a0e8a57f.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1.5rem;
  display: inline-block;
  position: relative;
}

.wp-breadcrumbs__group > span span.breadcrumb_last {
  font-weight: 600;
}

.wp-breadcrumbs__group > span span:not(:first-child) {
  position: relative;
}

.wp-breadcrumbs__group > span span:not(:first-child):before {
  content: "";
  left: calc(var(--gap) / -2);
  background-color: #171429;
  width: .25rem;
  height: .25rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.entry-content > * + * {
  margin-block-start: 1rem;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #ebebeb;
  border: none;
  height: .0625rem;
  margin-block: 3rem;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  text-align: center;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  transform: none;
}

.entry-content blockquote {
  background-color: #171429;
  flex-direction: column;
  gap: .5rem;
  margin-block: 2rem;
  padding: 1rem;
  font-style: italic;
  font-weight: 400;
  display: flex;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (max-width: 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 400;
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}

.entry-content img {
  max-width: 100%;
  margin-block: 1rem;
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: #171429;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-color: #171429;
  border-radius: 50%;
  width: .5rem;
  height: .5rem;
  display: block;
  position: absolute;
  top: .5rem;
  left: 0;
}

.entry-content ul, .entry-content ol {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

p {
  margin-bottom: 0;
  font-weight: 400;
}

p a {
  display: inline;
}

.entry-content .table-responsive {
  border: 1rem solid #fff;
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #ebebeb;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content thead {
  color: #fff;
  background-color: #171429;
  font-size: 1rem;
  font-weight: bold;
}

.entry-content tbody {
  color: #1d1d1b;
  background-color: #fff;
  font-size: .875rem;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: #ebebeb63;
}

.entry-content tbody td, .entry-content tbody th {
  padding: .5rem;
}

.entry-content tbody td:before, .entry-content tbody th:before {
  content: "";
  background: #ebebeb;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content tbody td:last-child:before, .entry-content tbody th:last-child:before {
  display: none;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.g-search-results {
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  display: flex;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  text-decoration-color: #0000;
}

.i-search-result__heading-link:hover {
  text-decoration-color: #171429;
}

.i-search-result__link {
  text-decoration-color: #0000;
  transition-property: color, text-decoration-color;
}

.i-search-result__link:hover {
  color: #171429;
  text-decoration-color: #171429;
}

.entry-content .gallery-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.entry-content .gallery-item a {
  transition: opacity .3s;
  display: block;
}

.entry-content .gallery-item a:hover {
  opacity: .7;
}

.entry-content .gallery-item img {
  object-fit: contain;
  height: 100%;
  margin: 0 auto;
}

.search-form__wrap {
  align-items: center;
  gap: 1rem 2rem;
  max-width: 80ch;
  display: flex;
  position: relative;
}

@media (max-width: 61.999em) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 .0625rem #ebebeb;
}

.search-form__input {
  color: #1d1d1b;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: 0 1rem;
}

@media (max-width: 35.999em) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: background-color .2s;
  display: flex;
}

.search-form__submit svg {
  fill: #171429;
  width: 1.125rem;
  height: 1.125rem;
  transition: fill .2s;
}

.search-form__submit:hover {
  background-color: #171429;
}

.search-form__submit:hover svg {
  fill: #fff;
}

.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
}

.entry-content .gallery > br {
  display: none;
}

.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 47.999em) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 47.999em) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}

/*# sourceMappingURL=index.css.map */
