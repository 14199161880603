@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
  }

  @include media-breakpoint-down(lg) {
    --shadow: transparent;

    position: fixed;
    right: 0;
    top: 0;
    background-color: colors.$primary;
    box-shadow: var(--shadow);
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: var(--transition-timing) ease;
    transition-property: transform, box-shadow;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &.--open {
      --shadow: #{shadows.$shadow};

      transform: translateX(0);
    }

    &.--transition {
      --transition-timing: 0.3s;
    }
  }
}

.m-nav__list {
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }

  @include media-breakpoint-down(lg) {
    padding-inline: 8px;
  }
}
