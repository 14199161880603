@use "settings/s-colors.scss" as colors;

.f-label {
  --top: auto;
  --bottom: 50%;
  --translate-y: 50%;
  --color: #{colors.$primary};

  position: absolute;
  top: var(--top);
  bottom: var(--bottom);
  left: 0;
  margin-right: 1px;
  transform: translateY(var(--translate-y));
  transition: 0.3s ease;
  transition-property: transform, color, font-size, top, bottom, left;
  color: var(--color);
  background-color: colors.$conversion-500;
  font-size: 16px;
  line-height: 20px;
  pointer-events: none;
  padding-inline: 8px;
  z-index: 2;

  &.--required {
    &::after {
      content: "*";
      color: var(--color);
      padding-left: 4px;
    }
  }

  &.--active {
    --bottom: 0;

    font-size: 12px;
    line-height: 14px;
  }
}
