html {
  height: 100%;
  text-size-adjust: none;
  scroll-behavior: smooth;
  scroll-padding-top: 128px;

  @include media-breakpoint-down(xxl) {
    scroll-padding-top: 124px;
  }

  @include media-breakpoint-down(xl) {
    scroll-padding-top: 84px;
  }

  @include media-breakpoint-down(sm) {
    scroll-padding-top: 76px;
  }
}
