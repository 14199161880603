.btn {
  &.--md {
    --min-height: 48px;
  }

  &.--sm {
    --font-size: 14px;
    --padding-y: 10px;
    --padding-x: 24px;
    --min-height: 40px;
  }

  &.--xs {
    --font-size: 12px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 40px;
    --svg-size: 16px;
  }

  &.--xxs {
    --font-size: 16px;
    --padding-y: 4px;
    --padding-x: 16px;
    --min-height: 20px;
    --svg-size: 16px;
  }
}
