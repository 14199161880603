@use "settings/s-colors" as colors;

.i-alert {
  --background: #{colors.$white};
  --border-color: #{colors.$gray-200};
  --color: #{colors.$black};
  --opacity: 0;
  --translate: -32px;
  --icon-background: #{colors.$white};

  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  border-width: 1px;
  border-style: solid;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  border-radius: 4px;

  &.--show {
    --opacity: 1;
    --translate: 0;

    pointer-events: auto;
  }

  &.--success {
    --border-color: #{colors.$success};
    --background: #{colors.$success-200};
    --color: #{colors.$success};
    --icon-background: #{colors.$success};
  }

  &.--danger {
    --border-color: #{colors.$danger};
    --background: #{colors.$danger-200};
    --color: #{colors.$danger};
    --icon-background: #{colors.$danger};
  }

  &.--warning {
    --border-color: #{colors.$warning};
    --background: #{colors.$warning-200};
    --color: #{colors.$warning};
    --icon-background: #{colors.$warning};
  }

  &.--primary {
    --border-color: #{colors.$primary};
    --background: #{colors.$primary};
    --color: #{colors.$white};
    --icon-background: #{colors.$primary};
  }
}

.i-alert__icon,
.i-alert__close {
  background-color: var(--icon-background);
}
