.i-breadcrumb {
  &.--homepage {
    .i-breadcrumb__link {
      gap: 0;
    }

    .i-breadcrumb__divider {
      display: none;
    }
  }
}
