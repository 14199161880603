@use "settings/s-colors" as colors;

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;

  &:hover {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;
  }
}

.c-language-select__drowpdown {
  padding: 0 4px 4px;
  background-color: colors.$primary-400;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
}

.c-language-select__active {
  min-height: 40px;
  gap: 4px;
  padding-left: 16px;
  padding-right: 12px;
}

.c-language-select__dropdown-link {
  color: colors.$white;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    color: colors.$white;
    background-color: colors.$primary;
  }

  &:focus {
    color: colors.$white;
  }
}
