@use "settings/s-colors.scss" as colors;

.main-footer-column__item {
  --opacity: 0;

  &.--current {
    --opacity: 1;
  }
}

.main-footer-column__link {
  --color: #{colors.$white};
  --background: transparent;

  text-decoration: none;
  color: var(--color);
  background-color: var(--background);
  transition: background-color 0.3s ease;

  &:hover {
    --background: #{colors.$primary-400};

    color: var(--color);
  }

  &:focus {
    color: var(--color);
  }
}

.main-footer-column__icon {
  opacity: var(--opacity);
  pointer-events: none;
  transform: translate(-50%, -10px);
  transition: opacity 0.3s ease;
}
