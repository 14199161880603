@use "settings/s-colors" as colors;

.widget_search {
  form > div {
    display: flex;
    gap: 8px;
  }

  input[type="text"] {
    --border-color: #{colors.$gray};
    --radius: 4px;

    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    display: block;
    width: 100%;
    background-clip: padding-box;
    appearance: none;
    min-height: 46px;
    padding-left: 8px;

    &:focus {
      border-color: none;
      outline: none;
    }
  }

  input[type="submit"] {
    --border-radius: 6px;
    --background: #{colors.$primary};
    --font-color: #{colors.$white};

    color: var(--font-color);
    background-color: var(--background);
    border-radius: var(--border-radius);
    display: block;
    height: 100%;
    user-select: none;
    border: none;
    z-index: 1;
    text-decoration: none;
    touch-action: manipulation;
    padding-block: 8px;
    padding-inline: 16px;
    min-height: 46px;
    transition: background-color 0.3s ease;

    &:hover {
      text-decoration: none;
      color: var(--font-color);
    }

    &:focus,
    &:active {
      outline: 0;
      color: var(--font-color);
      text-decoration: none;
    }

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary-400};
      }
    }
  }
}
