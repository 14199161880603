@use "settings/s-colors" as colors;

.g-jobs__inner-row {
  margin: -1px;
}

.g-jobs__cell {
  --border: 1px solid #{colors.$primary};

  border: var(--border);
}
