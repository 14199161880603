@use "settings/s-general" as general;
@use "settings/s-colors" as colors;

$padding-x: 8px;
$padding-y: 16px;

$background: transparent;
$color-placeholder: colors.$primary;

$text-area-min-height: 92px;

.f-base {
  display: block;
  width: 100%;
  background-color: $background;
  color: colors.$primary;
  background-clip: padding-box;
  padding: $padding-y $padding-x;
  padding-right: 32px;
  appearance: none;
  border: none;
  min-height: 52px;

  &:focus {
    border-color: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $color-placeholder;
  }
}

textarea.f-base {
  min-height: $text-area-min-height;
  padding-right: 40px;

  &::-webkit-resizer {
    background-image: url("../images/svg/drag.svg");
    background-repeat: no-repeat;
    background-position: 25% 75%;
    width: 16px;
    height: 16px;
    transform: translateX(-32px);
  }
}
