@use "settings/s-colors" as colors;

.btn {
  &.--primary {
    --background: #{colors.$conversion-500};

    background-color: var(--background);
    z-index: 1;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      pointer-events: none;
      z-index: -2;
      background-color: rgba(#fff, 0.3);
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      pointer-events: none;
      background-color: var(--background);
      z-index: -1;
      transform: translateY(0);
      transition: transform 0.3s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        transform: translateY(100%);
      }
    }
  }

  &.--secondary {
    --background: #{colors.$primary-400};
    --background-cover: #{colors.$primary};
    --font-color: #{colors.$conversion-500};

    background-color: var(--background);
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      pointer-events: none;
      background-color: var(--background-cover);
      z-index: -1;
      transform: translateY(0);
      transition: transform 0.3s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        transform: translateY(100%);
      }
    }
  }

  &.--secondary-revert {
    --background: #{colors.$primary};
    --background-cover: #{colors.$primary-400};
    --font-color: #{colors.$conversion-500};

    background-color: var(--background);
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      pointer-events: none;
      background-color: var(--background-cover);
      z-index: -1;
      transform: translateY(0);
      transition: transform 0.3s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        transform: translateY(100%);
      }
    }
  }

  &.--outline {
    --font-color: #{colors.$conversion-500};
    --border-color: #{colors.$primary};
    --background: transparent;

    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition:
      background-color 0.3s ease,
      border-color 0.3s ease;
    will-change: background-color, border-color;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary};
      }
    }
  }

  &.--ghost {
    --background: transparent;
    --font-color: #{colors.$primary};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    will-change: background-color;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary-100};
      }
    }
  }

  &.--social {
    --background: #{colors.$white};
    --font-color: #{colors.$primary};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    will-change: background-color;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$white};
      }
    }
  }
}
