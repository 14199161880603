@use "settings/s-colors.scss" as colors;

.u-color-primary {
  color: colors.$primary;
}

.u-color-white {
  color: colors.$white;
}

.u-color-conversion {
  color: colors.$conversion-500;
}
