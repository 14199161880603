.s-steps__img {
  width: 352px;

  @include media-breakpoint-down(xxl) {
    width: 240px;
  }
}

.s-steps__side-img img {
  aspect-ratio: 1 / 1.997;
}

.s-steps__img-decor {
  transform: translate(-32px, 32px);
}
