@use "settings/s-colors.scss" as colors;

$transition: cubic-bezier(0.68, -0.55, 0.27, 1.55);

.c-scroll-up {
  --size: 40px;
  --offset: 40px;
  --radius: 0;
  --background: transparent;
  --background-hover: #{colors.$primary};
  --icon-size: 16px;
  --icon-color: #{colors.$white};

  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border: 1px solid colors.$primary;
  border-radius: var(--radius);
  background-color: var(--background);
  z-index: 50;
  transition:
    background-color 0.3s ease,
    opacity 0.3s $transition,
    transform 0.3s $transition;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(0.8);
  cursor: pointer;

  &:hover {
    --background: var(--background-hover);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-property: opacity, box-shadow;
    box-shadow: 0 0 10px 40px colors.$primary;
  }

  &:active::after {
    box-shadow: 0 0 0 0 colors.$primary;
    position: absolute;
    opacity: 1;
    transition: 0s;
  }

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    rotate: -90deg;
  }

  path {
    fill: var(--icon-color);
  }

  &.--active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0) scale(1);
  }
}
