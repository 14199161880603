.s-text-media__grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-text-media__col.--content-col {
  order: var(--content-order);
  padding-left: var(--content-offset-left);
  padding-right: var(--content-offset-right);
}

.s-text-media__img-decor {
  transform: translate(-32px, 44px);

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.s-text-media {
  --content-offset-left: 40px;
  --content-offset-right: 0;
  --content-order: 1;

  @include media-breakpoint-down(lg) {
    --content-offset-left: 0;
  }

  &.--reverse {
    --content-offset-left: 0;
    --content-offset-right: 40px;

    @include media-breakpoint-up(lg) {
      --content-order: -1;
    }

    @include media-breakpoint-down(lg) {
      --content-offset-right: 0;
    }

    .s-text-media__img-decor {
      left: auto;
      right: 0;
      transform: translate(32px, 44px);
    }
  }
}
