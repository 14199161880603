.main-footer__columns {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr) 240px;
  }
}

.main-footer__top {
  padding-top: 96px;
  padding-bottom: 64px;

  @include media-breakpoint-down(sm) {
    padding-top: 48px;
    padding-bottom: 32px;
  }
}
