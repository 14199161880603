@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-header {
  --shadow-color: transparent;
  --min-height: 128px;
  --padding-y: 24px;

  top: 0;
  left: 0;
  position: sticky;
  z-index: 5;
  background-color: colors.$primary;
  box-shadow: 0 4px 40px 0 var(--shadow-color);
  transition: box-shadow 0.3s ease;
  transition-property: box-shadow, transform;

  &.headroom--not-top {
    --shadow-color: #{shadows.$color};
  }
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--padding-y);

  @include media-breakpoint-between(xl, xxl) {
    --min-height: 124px;
    --padding-y: 16px;
  }

  @include media-breakpoint-down(xl) {
    --min-height: 88px;
    --padding-y: 4px;
  }
}

.m-header__strip {
  background: colors.$conversion-500;
  height: 4px;
  width: 100%;
  max-width: 100%;
}
