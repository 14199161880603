@use "settings/s-colors" as colors;

.f-file {
  &.--empty {
    .f-file__files {
      display: none;
    }
  }
}

.f-file__input {
  display: none;
}
