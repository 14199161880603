.main-footer-column {
  @include media-breakpoint-down(sm) {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__content.entry-content {
  p:last-child {
    margin-bottom: 0;
  }
}

.main-footer-column__logo {
  max-width: 138px;
  max-height: 80px;
}

.main-footer-column__socials {
  margin-top: 64px;
}

.main-footer__contact-icon {
  width: 72px;
  height: 72px;
}

.main-footer__mail {
  word-break: break-all;
}

.main-footer-column__title {
  font-weight: 700;
}
