@use "settings/s-colors" as colors;

.f-text__icon {
  padding: 8px;
  position: absolute;
  pointer-events: none;

  &.--before {
    padding-left: 16px;
    left: 0;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  &.--after {
    padding-right: 16px;
    right: 0;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--color);
  transition: color 0.3s ease;
}

.f-text {
  --color: #{colors.$primary};
  --border-color: #{colors.$primary};
  --radius: 0;

  &.--invalid {
    --color: #{colors.$danger};
    --border-color: #{colors.$danger};

    .f-label {
      --color: #{colors.$danger};
    }
  }

  &.--icon-before {
    .f-text__input {
      padding-left: 48px;
    }
  }

  &.--icon-after {
    .f-text__input {
      padding-right: 48px;
    }
  }
}

.f-text__wrap {
  border-bottom: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color 0.3s ease;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 24px;

  right: 16px;
  width: var(--size);
  height: var(--size);

  &.--active {
    --on-opacity: 1;
    --off-opacity: 0;
  }
}

.f-text__toggle-icon {
  grid-column-start: 1;
  grid-row-start: 1;
  width: var(--size);
  height: var(--size);

  &.--on {
    opacity: var(--on-opacity);
  }

  &.--off {
    opacity: var(--off-opacity);
  }
}
