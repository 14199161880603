@use "settings/s-colors.scss" as colors;

.u-bg-primary {
  background-color: colors.$primary;
}

.u-bg-primary-100 {
  background-color: colors.$primary-100;
}

.u-bg-primary-400 {
  background-color: colors.$primary-400;
}

.u-bg-conversion {
  background-color: colors.$conversion-500;
}

.u-bg-gray {
  background-color: colors.$gray;
}

.u-bg-white {
  background-color: colors.$white;
}
