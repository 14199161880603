@use "settings/s-colors" as colors;

.i-reviews-slide {
  --transform: scale(1);

  &.--active {
    --transform: scale(-1);
  }
}

.i-reviews-slide__content.entry-content {
  line-height: 20px;
  transition: max-height 0.3s ease;

  p:last-child {
    margin-bottom: 0;
  }
}

.i-reviews-slide__footer {
  border-left: 1px solid colors.$white;
  padding-block: 6px;
}

.i-reviews-slide__profile {
  gap: 6px;
}

.i-reviews-slide__more {
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: colors.$conversion-500;
  }
}

.i-reviews-slide__more-text {
  gap: 4px;

  svg {
    transform: var(--transform);
  }
}
