.m-header-logo {
  img {
    max-height: 80px;
  }

  @include media-breakpoint-down(xl) {
    img {
      max-height: 64px;
    }
  }

  @include media-breakpoint-down(sm) {
    max-width: 120px;

    img {
      max-height: 56px;
    }
  }
}
